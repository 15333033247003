import axios from 'axios'
import React, { lazy, useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import styles from './BlikPage.module.scss'
// import { MuiOtpInput } from 'mui-one-time-password-input'
import { pay } from '../../model/cardSagas/cardActions'

import { ReactComponent as BlikLogo } from '../../assets/images/blik.svg'; // Adjust the path accordingly
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import InputBase from '@material-ui/core/InputBase';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import CircularProgress from '@material-ui/core/CircularProgress'
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';

const storeSetIsSubmitted = (invoiceId) => {
  localStorage.setItem('blik-summitted-invoice-' + invoiceId, true);
}

const storeGetIsSubmitted = (invoiceId) => {
  return localStorage.getItem('blik-summitted-invoice-' + invoiceId);
}



export const BlikPage = () => {
    const [otp, setOtp] = useState(new Array(6).fill(""));
    const [submitted, setSubmitted] = useState(false)
    const [isError, setIsError] = useState(false)
    const [isAlreadySubmittedError, setisAlreadySubmittedError] = useState(false)
    const inputRefs = useRef([]);
    const dispatch = useDispatch()

    useEffect(() => {

      const urlParamsObj = new URLSearchParams(window.location.search);
      const urlParams = {}

      for (const key of urlParamsObj.keys()) {
          urlParams[key] = urlParamsObj.get(key)
      }

      if (storeGetIsSubmitted(urlParams['invoice_id'])) {
        setisAlreadySubmittedError(true)
      }
    }, []);


    const canSubmit = () => {
      return !submitted && otp.join("").length === 6
    }

    // Handle OTP input (works for both desktop and mobile)
    const handleInput = (e, index) => {
      const value = e.target.value;
  
      if (/^[0-9]$/.test(value)) {
        let newOtp = [...otp];
  
        newOtp[index] = value;
        setOtp(newOtp);
  
        // Move to the next input
        if (index < otp.length - 1) {
          inputRefs.current[index + 1].focus();
        }
  
        // Auto submit if the last field is filled
        if (index === otp.length - 1) {
            // handleSubmit(newOtp);
        }
      } else {
        e.target.value = ""; // Clear the input if the value is invalid
      }
    };
  
    // Handle backspace for desktop (keydown)
    const handleKeyDown = (e, index) => {
      if (e.key === "Backspace") {
        let newOtp = [...otp];
  
        // If the current field has a value, clear it
        if (otp[index] !== "") {
          newOtp[index] = "";
          setOtp(newOtp);
        } 
        // If the current field is empty, move to the previous field and clear that
        else if (index > 0) {
          inputRefs.current[index - 1].focus();
          newOtp[index - 1] = "";
          setOtp(newOtp);
        }
      } else if (e.key === "Enter") {
        if (canSubmit()) {
          handleSubmit(otp)
        }
      } else if (e.target.value != "") {
            e.target.value = ""
            handleInput(e, index)        
      } 
    };
  
    // Handle backspace for mobile (input event)
    const handleBackspaceMobile = (e, index) => {
      if (e.inputType === "deleteContentBackward") {
        let newOtp = [...otp];
  
        // If current field has a value, clear it
        if (otp[index] !== "") {
          newOtp[index] = "";
          setOtp(newOtp);
        } 
        // Move to the previous field if necessary
        else if (index > 0) {
          inputRefs.current[index - 1].focus();
          newOtp[index - 1] = "";
          setOtp(newOtp);
        }
      }
    };
  
    // Handle paste event
    const handlePaste = (e) => {
      const pasteData = e.clipboardData.getData("text").split("").slice(0, otp.length);

      if (pasteData.length === otp.length) {
        setOtp(pasteData);
        inputRefs.current[otp.length - 1].focus();
      }
    };
  
    // Simulate submit
    const handleSubmit = async (otp) => {
        if (!canSubmit()) {
            return
        }
        setSubmitted(true)        

        const urlParamsObj = new URLSearchParams(window.location.search);
        const urlParams = {}

        for (const key of urlParamsObj.keys()) {
            urlParams[key] = urlParamsObj.get(key)
        }

        storeSetIsSubmitted(urlParams['invoice_id'])        

        await axios.post('/form/pay', {
            invoice_id: urlParams['invoice_id'],
            merchant_id: urlParams['merchant_id'],
            endpoint_id: urlParams['endpoint_id'],
            amount: urlParams['amount'],
            order: urlParams['order'],
            signature: urlParams['signature'],
            currency: urlParams['currency'],
            first_name: urlParams['first_name'],
            last_name: urlParams['last_name'],
            email: urlParams['email'],
            phone: urlParams['phone'],
            otp: otp.join(""),
        })
        .then(function (response) {
            // console.log(response.data)

            if (response?.data?.status == 'redirect') {
                window.location.href = response.data.url;
            } else {
                setIsError(true)
                console.log("Unknown status: " + response?.data?.payment_status);
            }               
        })
        .catch(function (error) {
            setIsError(true)
            console.log(error);
        });

    };

    const handlePasteButtonClick = async () => {
      try {
        // Check if the Clipboard API is available
        if (navigator.clipboard && navigator.clipboard.readText) {
          const text = await navigator.clipboard.readText();
          const digits = text.trim().split('').filter((char) => /^[0-9]$/.test(char)).slice(0, 6);
          if (digits.length === 6) {
            setOtp(digits);
            inputRefs.current[digits.length - 1]?.focus();
          } else {
            alert('Clipboard does not contain a valid 6-digit code.');
          }
        } else {
          alert('Clipboard API not available.');
        }
      } catch (err) {
        console.error('Failed to read clipboard contents: ', err);
        alert('Failed to read clipboard contents.');
      }
    };

    return (
      <div className={styles.otpContainer} >        
        <BlikLogo width={150} />        
        <h2 className={styles.otpHeader}>Wprowadź kod BLIK</h2>
        {!submitted && !isAlreadySubmittedError &&
          <>
            <div className={styles.otpField}>
              <div>
                {otp.map((value, index) => (
                  <input
                    key={index}
                    disabled={submitted}
                    type="number"
                    maxLength="1"
                    value={value}
                    onChange={(e) => handleInput(e, index)}  // Works for both desktop and mobile
                    onKeyDown={(e) => handleKeyDown(e, index)}  // Desktop backspace handling
                    onInput={(e) => handleBackspaceMobile(e, index)}  // Mobile backspace handling
                    onPaste={handlePaste}
                    ref={(el) => (inputRefs.current[index] = el)}
                    // style={{ width: `${inputWidth}px` }} // Set input width dynamically
                  />
                ))}
              </div>
            <div className={styles.otpPasteContainer}>
              {false && <button
                id="paste-button"
                onClick={handlePasteButtonClick}
                className={styles.otpPasteButton}
              >
                wklej
              </button>}
            </div>
            </div>
            </>
          }

        {submitted && !isError &&  <div className={styles.otpLoaderContainer}>
          <div className={styles.otpLoader}></div>
        </div>}

        {!submitted && !isAlreadySubmittedError && <div className={styles.otpSubmitContainer}>        
          <button 
            id="submit-button" 
            disabled={!canSubmit()}
            onClick={() => handleSubmit(otp)} 
            className={styles.otpSubmitButton}
            >wyślij</button>
        </div>}

        {isError && <div className={styles.otpErrorMessage}>
          Wystąpił błąd, spróbuj ponownie później.
        </div>}

        {isAlreadySubmittedError && 
        <>
          <div className={styles.otpErrorMessage}>
            Już wysłaliście zapytanie. Proszę wrócić na stronę sklepu i zainicjować nową transakcję.
          </div>
        </>
        }
          
        <p className={styles.termsLink}>
          Kontynuując, zgadzasz się na nasze  <a href="https://www.mobilum.com/docs/Mobilum-Terms-and-Conditions-of-Services-in-Partners-Applications.pdf" target="_blank">Warunki i zasady</a>.
        </p>

      </div>
    );
  };
  